<template>
      <Dialog header="Tu privacidad" :visible="display" :closable="false" :breakpoints="{'960px': '75vw', '640px': '90vw'}" :style="{width: '50vw'}" position="bottomright" :modal="true">
          <p class="m-0">Este sitio web utiliza cookies que permiten el funcionamiento y la prestación de los servicios ofrecidos en el mismo. Estas cookies <b>no recogen ni almacenan sus datos personales</b>.</p>
          <template #footer>
              <Button label="Entendido" icon="pi pi-check" class="cookies-button" @click="close" autofocus />
              <Button label="Más información" icon="pi pi-info-circle" @click="info" class="p-button-text" />
          </template>
      </Dialog>
</template>

<script>
import { } from '@ionic/vue';
import {  } from 'ionicons/icons';

import Dialog from 'primevue/dialog';
import Button from 'primevue/button';

import { defineComponent,  } from 'vue';
import { mapActions, mapState } from 'vuex';

export default defineComponent({
  name: 'HeaderComponent',
  props: ['title', 'crumbs', 'background'],
  components: {
    Dialog,
    Button
  },
  data() {
    return {
      display: true,
    }
  },
  methods:{
    close(){
      this.display = false;
      localStorage.setItem('cookies', JSON.stringify({
          value: false,
          expiry: (new Date().getTime() + 90*24*3600*1000),
        })
      );
    },

    info(){
      window.open('/privacidad', '_blank').focus();
    }
  },
  created(){
    if(this.$route.name === 'Privacity'){
      this.display = false;
    } else {
      const cookies = JSON.parse(localStorage.getItem('cookies'));

      if(cookies){
        if(new Date().getTime() > cookies.expiry){
          localStorage.removeItem('cookies');
        } else {
          this.display = cookies.value;
        }
      }
    }

  },
  setup() {
    return {};
  }
});
</script>

<style scoped>
    
.p-button {
    margin: 0.3rem .5rem;
    min-width: 10rem;
}

p {
    margin: 0;
}

.confirmation-content {
    display: flex;
    align-items: center;
    justify-content: center;
}



.p-dialog .p-button {
    min-width: 6rem;
    margin: 0.25rem auto;
}


.p-button-text:focus{
  background: rgba(0, 0, 0, 0.12) !important;
}

  @media (min-width: 960px) and (max-width: 1140px) {

  }

  @media (min-width: 1140px) {

  }

</style>