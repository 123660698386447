<template>
  <ion-menu side="start" menu-id="mainMenu" content-id="main" class="mainMenu">
    <ion-content class="main-nav">
      <div class="menu-container">
        <ion-header class="ion-no-border" id="header">
            <ion-toolbar color="transparent">
              <ion-buttons slot="end">
                <ion-button @click="close">
                  <ion-icon slot="icon-only" color="light" :icon="closeOutline"></ion-icon>
                </ion-button>
              </ion-buttons>
            </ion-toolbar>
          </ion-header>

        <ion-list lines="none" class="modules-list">
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/` })" detail="false">
                <ion-label color="light"><strong>Inicio</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="primary" slot="end" item-right></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/servicios` })" detail="false">
                <ion-label color="light"><strong>Servicios</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="primary" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/guardamuebles` })" detail="false">
                <ion-label color="light"><strong>Guardamuebles</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="primary" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/sobre-nosotros` })" detail="false">
                <ion-label color="light"><strong>Sobre Nosotros</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="primary" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-item button @click="$router.push({ path: `/contacto` })" detail="false">
                <ion-label color="light"><strong>Contacto</strong></ion-label>
                <ion-icon :icon="chevronForwardOutline" color="primary" slot="end"></ion-icon>
              </ion-item>
            </ion-menu-toggle>
            <ion-menu-toggle auto-hide="false">
              <ion-button shape="round" expand="full" color="primary" style="margin: 2rem 0;" target="_blank" @click="$router.push({ path: `/presupuesto` })" >
                <ion-label color="dark"><strong>Pide tu presupuesto</strong></ion-label>
              </ion-button>
            </ion-menu-toggle>
        </ion-list>
      </div>
      
      <ion-buttons class="menu-bottom">
        <ion-button fill="outline" color="light" style="--border-radius: 100%; width: 48px; height: 48px; margin-right: 1rem;" href="https://www.facebook.com/crespotransporte" target="_blank">
          <ion-icon :icon="logoFacebook" slot="icon-only"></ion-icon>
        </ion-button>
        <ion-button fill="outline" color="light" style="--border-radius: 100%; width: 48px; height: 48px;" href="https://www.instagram.com/mudanzascrespochiclana/" target="_blank">
          <ion-icon :icon="logoInstagram" slot="icon-only"></ion-icon>
        </ion-button>
      </ion-buttons>
    </ion-content>
  </ion-menu>
</template>

<script>
import { IonMenu, IonList, IonItem, IonMenuToggle, menuController, IonButtons, IonButton, IonLabel, IonIcon, IonHeader, IonToolbar, IonContent } from '@ionic/vue';
import { chevronForwardOutline, bagHandleOutline, closeOutline, callOutline, calendarOutline, logoInstagram, logoFacebook } from 'ionicons/icons';
import { defineComponent } from 'vue';
import { mapState } from 'vuex';

import Tooltip from 'primevue/tooltip';

export default defineComponent({
  name: 'MenuComponent',
  components: {
    IonMenu,
    IonList,
    IonItem,
    IonMenuToggle,
    IonButtons,
    IonButton,
    IonLabel,
    IonIcon,
    IonHeader,
    IonToolbar,
    IonContent
  },
  directives: {
    'tooltip': Tooltip
  },
  data() {
    return {
      openList: [],
    }
  },
  computed: {
      ...mapState(['evolbeUser'])
  },
  methods:{
    close(){
      menuController.close();
    }
  },
  setup() {
    return { chevronForwardOutline, bagHandleOutline, closeOutline, callOutline, calendarOutline, logoInstagram, logoFacebook };
  }
});
</script>

<style>

  .mainMenu{
    --background: var(--ion-color-dark);
    z-index: 800;
  }

  .main-nav{
    --background: var(--ion-color-dark);
    --color: var(--ion-color-light);
    height: 100vh;
  }

  .menu-container{
    padding: 2rem;
  }
  
  .modules-list{
    padding: 1rem;
  }

  .modules-list ion-label{
    text-transform: capitalize;
  }

  .modules-list ion-label:hover, .modules-list ion-label:active{
    color: var(--ion-color-tertiary);
  }

  .hidden{
    display: none;
  }

  #header{
    height: 5rem;
  }

  .menu-bottom{
    width: 100%;
    position: absolute;
    bottom: 2rem;
    left: 2rem;
  }

</style>