/* eslint-disable no-unused-vars */
import { createStore } from 'vuex'
import { storage } from '@/firebaseConfig';

function getFileBlob(url, cb) {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", url);
    xhr.responseType = "blob";
    xhr.addEventListener('load', function() {
      cb(xhr.response);
    });
    xhr.send();
  }

export const store = createStore({
    state: {

    },
    mutations: {

    },
    actions: {
        uploadImage({commit}, {ruta, img}){
            getFileBlob(img.objectURL, blob => {
                storage.ref(ruta).child(img.id + '.jpg').put(blob).then((result) => {
                return result.state;
                }).catch((err) => {
                console.log('error:', err);
                return 'error';
                });
            })
        },
    },
    getters: {
    },
    modules: {},
});
  