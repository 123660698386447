import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import HomePage from '../views/HomePage.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'HomePage',
    component: HomePage
  },
  {
    path: '/servicios',
    name: 'Services',
    component: () => import('@/views/Services.vue'),
  },
  {
    path: '/guardamuebles',
    name: 'Guardamuebles',
    component: () => import('@/views/Guardamuebles.vue'),
  },
  {
    path: '/presupuesto',
    name: 'Presupuesto',
    component: () => import('@/views/Presupuesto.vue'),
  },
  {
    path: '/sobre-nosotros',
    name: 'About',
    component: () => import('@/views/About.vue'),
  },
  {
    path: '/contacto',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
  },

  {
    path: '/aviso-legal',
    name: 'LegalWarning',
    component: () => import('@/views/legal/LegalWarning.vue'),
  },
  {
    path: '/privacidad',
    name: 'Privacity',
    component: () => import('@/views/legal/Privacity.vue'),
  },
  {
    path: '/cookies',
    name: 'Cookies',
    component: () => import('@/views/legal/Cookies.vue'),
  },
  {
    path: '/consentimiento-web',
    name: 'ConsentimientoWeb',
    component: () => import('@/views/legal/WebConsent.vue'),
  },

  { 
    path: '/:catchAll(.*)',
    redirect: '/'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
