import firebase from 'firebase/app';
import 'firebase/firestore'
import "firebase/storage";

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAiZepsTLVsVaUfrPavf_JFIGMyyjnQifE",
  authDomain: "mudanzascrespochiclana-com.firebaseapp.com",
  projectId: "mudanzascrespochiclana-com",
  storageBucket: "mudanzascrespochiclana-com.appspot.com",
  messagingSenderId: "298698025429",
  appId: "1:298698025429:web:ce8cce64405792934bb55c",
  measurementId: "G-FW28HGP9DH"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();
firebase.firestore().settings({
  ignoreUndefinedProperties: true,
});

export const storage = firebase.storage();