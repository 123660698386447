<template>
  <ion-page>
    
    <Header activePage="Inicio"></Header>
    
    <ion-content :fullscreen="true">

    <section id="slider">
      <swiper class="swiper-sliders" :modules="modules" :pagination="{dynamicBullets: true,}" :slidesPerView="1" :autoplay="true" :loop="true">
        <swiper-slide>
          <div class="swiper-slider">
            <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fslide1_2048x2048.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
          
            <div class="container" style="height: 100%; position: relative;">
              <div class="message">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h1 class="text-light">Hacemos fácil tu mudanza</h1>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <p class="text-light mt-xs mb-xs">Desmontar, embalar, cargar, transportar, descargar, volver a montar todo.. Nos ocupamos de cualquier necesidad que pueda surgir durante tu mudanza.</p>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeIn animate__delay-1s">
                <ion-button color="primary" router-link="/presupuesto" class="mt-xxs">
                  <ion-label>Pide tu Presupuesto</ion-label>
                  <ion-icon slot="end" :icon="arrowForwardSharp"></ion-icon>
                </ion-button>
                </AnimatedComponent>
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="swiper-slider">
            <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fslide2_2048x2048.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
          
            <div class="container" style="height: 100%; position: relative;">
              <div class="message">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h1 class="text-light">Seguridad garantizada en tu Guardamuebles</h1>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <p class="text-light mt-xs mb-xs">Te ofrecemos infraestructuras especialmente acondicionadas para el almacenaje de muebles y enseres personales, garantizando el mejor almacenamiento con seguridad, privacidad y limpieza. </p>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeIn animate__delay-1s">
                <ion-button color="primary" router-link="/guardamuebles" class="mt-xxs">
                  <ion-label>Guardamuebles</ion-label>
                  <ion-icon slot="end" :icon="arrowForwardSharp"></ion-icon>
                </ion-button>
                </AnimatedComponent>
              </div>
            </div>
          </div>
        </swiper-slide>

        <swiper-slide>
          <div class="swiper-slider">
            <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fslide3_2048x2048.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
          
            <div class="container" style="height: 100%; position: relative;">
              <div class="message">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h1 class="text-light">Transportes al mejor precio</h1>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <p class="text-light mt-xs mb-xs">Podrás aprovechar rutas compartidas a bajo coste, con seguros incluidos y todas las garantías del mercado.</p>
                </AnimatedComponent>
                <AnimatedComponent animation-enter="animate__fadeIn animate__delay-1s">
                <ion-button color="primary" router-link="/servicios" class="mt-xxs">
                  <ion-label>Nuestros servicios</ion-label>
                  <ion-icon slot="end" :icon="arrowForwardSharp"></ion-icon>
                </ion-button>
                </AnimatedComponent>
              </div>
            </div>
          </div>
        </swiper-slide>

      </swiper>
    </section>

    <div class="container">
      <section id="contact-options" class="expanse">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="4">
              <ion-card button router-link="/presupuesto" class="presupuesto contact-option">
                <ion-card-content>
                  <ion-item lines="none">
                    <ion-label text-wrap><h3>Pide tu presupuesto</h3></ion-label>
                    <ion-icon :icon="arrowForwardSharp" slot="end"></ion-icon>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <ion-card button href="mailto:info@mudanzascrespochiclana.es" class="contact-option">
                <ion-card-content>
                  <h3>Email</h3>
                  <ion-item lines="none">
                    <ion-icon :icon="mailOutline" slot="start"></ion-icon>
                    <ion-label text-wrap>info@mudanzascrespochiclana.es</ion-label>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <ion-card button href="tel:639977027" class="contact-option">
                <ion-card-content>
                  <h3>Teléfono</h3>
                  <ion-item lines="none">
                    <ion-icon :icon="callOutline" slot="start"></ion-icon>
                    <ion-label text-wrap>639 97 70 27</ion-label>
                  </ion-item>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="numbers" class="mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div class="title">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h2>Somos especialistas en mudanzas y guardamuebles</h2>
                </AnimatedComponent>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-lg="6">
              <div id="camion1">
                <AnimatedComponent animation-enter="animate__slideInLeft">
                  <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fcamion1_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
                </AnimatedComponent>
              </div>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="12" size-lg="5">
              <ion-grid class="expanse mt-md">
                <ion-row>
                  <ion-col size="6">
                    <div class="contador-box">
                      <img src="assets/img/iconos/experiencia.png" alt="Mudanzas Chiclana Antonio Crespo" class="image-box"/>
                      <h4 class="text-center" id="contador">{{ annos }}</h4>
                      <p class="text-center">AÑOS DE EXPERIENCIA</p>
                    </div>
                  </ion-col>
                  <ion-col size="6">
                    <div class="contador-box">
                      <img src="assets/img/iconos/clientes.png" alt="Mudanzas Chiclana Antonio Crespo" class="image-box"/>
                      <h4 class="text-center" id="contador">+1500</h4>
                      <p class="text-center">CLIENTES SATISFECHOS</p>
                    </div>
                  </ion-col>
                  <ion-col size="6">
                    <div class="contador-box">
                      <img src="assets/img/iconos/mudanzas.png" alt="Mudanzas Chiclana Antonio Crespo" class="image-box"/>
                      <h4 class="text-center" id="contador">+1500</h4>
                      <p class="text-center">MUDANZAS REALIZADAS</p>
                    </div>
                  </ion-col>
                  <ion-col size="6">
                    <div class="contador-box">
                      <img src="assets/img/iconos/guardamuebles.png" alt="Mudanzas Chiclana Antonio Crespo" class="image-box"/>
                      <h4 class="text-center" id="contador">+1200m²</h4>
                      <p class="text-center">DE GUARDAMUEBLES</p>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="about" class="pt-xxl mb-xxl expanse">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="6">
              <div class="about-container">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h2 class="text-light">Te ofrecemos asistencia en todas las fases de tu mudanza</h2>
                </AnimatedComponent>
                <p class="pt-xs pb-xs text-light"> Deja en manos de nuestros profesionales tu tranquilidad. Nuestra especialidad es realizar servicios de calidad. El personal que asignamos para cada mudanza actúa con la mayor delicadeza y diligencia, puesto que somos  muy conscientes de la importancia de tratar adecuadamente y con mimo el mobiliario y los enseres personales de nuestros clientes.</p>

                <ion-list lines="none">
                  <AnimatedComponent animation-enter="animate__fadeInDown">
                  <ion-item class="about-services">
                    <img src="assets/img/iconos/presupuesto.png" alt="Mudanzas Chiclana Antonio Crespo" class="about-icon"/>
                    <ion-label text-wrap>
                      <h4 class="text-light">Presupuesto sin compromiso</h4>
                      <p class="text-light pt-xxs">Si desear afianzar nuestros servicios con su empresa o actividad no dudes en contactar con nosotros y un comercial se pasará por sus instalaciones para darle una solución a medida de sus necesidades.</p>
                    </ion-label>
                  </ion-item>
                  </AnimatedComponent>
                  <AnimatedComponent animation-enter="animate__fadeInDown">
                  <ion-item class="about-services">
                    <img src="assets/img/iconos/embalaje2.png" alt="Mudanzas Chiclana Antonio Crespo" class="about-icon"/>
                    <ion-label text-wrap>
                      <h4 class="text-light">Embalaje, Desmontaje, Montaje...</h4>
                      <p class="text-light pt-xxs">Embalamos, acondicionamos y preparamos todo lo necesario para que su mudanza se haga con rápida y segura. También nos encargamos del desmontaje del mobiliario y el monaje en su destino.</p>
                    </ion-label>
                  </ion-item>
                  </AnimatedComponent>
                  <AnimatedComponent animation-enter="animate__fadeInDown">
                  <ion-item class="about-services">
                    <img src="assets/img/iconos/guardamuebles.png" alt="Mudanzas Chiclana Antonio Crespo" class="about-icon"/>
                    <ion-label text-wrap>
                      <h4 class="text-light">Guardamuebles</h4>
                      <p class="text-light pt-xxs">¿Aún no tiene disponible su nueva vivienda y tiene de desalojar la actual? Disponemos de servicio de guardamuebles donde confinar todas sus pertenencias el tiempo necesario hasta finalizar su mudanza.</p>
                    </ion-label>
                  </ion-item>
                  </AnimatedComponent>
                </ion-list>

                <ion-button  class="mt-md" router-link="/contacto">
                  <ion-label>Contactar</ion-label>
                </ion-button>
              </div>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="12" size-lg="5">
              <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fabout_1024x1024.webp?alt=media" class="mt-md" style="border-radius: 0 0 0 1rem;" alt="Mudanzas Chiclana Antonio Crespo" id="about-image"/>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="services" class="pt-xxl mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div class="title">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h2>Nuestros servicios</h2>
                </AnimatedComponent>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Mudanzas Nacionales</h3>
                <p class="mt-xxs mb-xxs">Realizamos mudanzas desde cualquier parte del territorio nacional, siempre con la mayor delicadeza y diligencia. Somos muy conscientes de la importancia de tratar con mimo el mobiliario y los enseres personales de nuestros clientes.</p>
                <img src="assets/img/iconos/nacional.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Mudanzas Internacionales</h3>
                <p class="mt-xxs mb-xxs">Nos encargamos de mudanzas desde y hacia el extranjero gestionando la documentación necesaria para los tramites con las autoridades aduaneras de dicho país, ayudándole en el caótico proceso burocrático que puede ser comenzar a residir en otro país.</p>
                <img src="assets/img/iconos/internacional.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Mudanzas de Oficinas</h3>
                <p class="mt-xxs mb-xxs">En los traslados de oficinas y empresas es primordial mantenerse en funcionamiento. Por eso planificamos al detalle sus traslados internos o externos y los gestionamos de manera rápida e inmediata para que su actividad no se vea interrumpida.</p>
                <img src="assets/img/iconos/oficinas.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Transportes para Tiendas</h3>
                <p class="mt-xxs mb-xxs">Ofrecemos servicios para el traslado de locales comerciales y soluciones de almacenamiento temporales para comercios y empresas. Contamos con espacios dedicados al almacenamiento para mobiliario, mercancía, enseres, etc...</p>
                <img src="assets/img/iconos/tiendas.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Embalaje de Enseres</h3>
                <p class="mt-xxs mb-xxs">Nos encargamos del embalaje de tus enseres con la máxima sensibilidad y cuidado para traslado. No aseguramos de que no sufran ningún daño ni desperfecto durante el trayecto a su nuevo lugar. Contamos con todo el material especializado.</p>
                <img src="assets/img/iconos/embalaje.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="service-container">
                <h3>Seguro de Mudanzas</h3>
                <p class="mt-xxs mb-xxs">Trabajamos con las mejores empresas aseguradoras, que cubrirán totalmente los riesgos derivados del servicio. Tus pertenencias estarán seguras en todo el proceso, garantizando el trámite de indemnización en caso de siniestro.</p>
                <img src="assets/img/iconos/seguros.png" alt="Mudanzas Chiclana Antonio Crespo" class="service-icon"/>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12">
              <AnimatedComponent animation-enter="animate__fadeIn">
                <ion-button color="primary" class="service-button" router-link="/servicios">
                  <ion-label>Más servicios</ion-label>
                  <ion-icon slot="end" :icon="arrowForwardSharp"></ion-icon>
                </ion-button>
              </AnimatedComponent>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
    </div>

      <section id="guardamuebles" class="pt-xxl pb-xxl">
        <div class="container">
          <div style="max-width: 720px">
            <AnimatedComponent animation-enter="animate__fadeInDown">
            <h2 class="text-light">Guardamuebles</h2>
            </AnimatedComponent>
            <p class="mt-xs text-light">El servicio de guardamuebles cuenta con infraestructuras convenientemente acondicionadas para garantizar la seguridad, individualidad y limpieza de tus enseres personales.</p>
            <AnimatedComponent animation-enter="animate__fadeIn">
            <ion-button class="mt-md mb-md" router-link="/guardamuebles">
              <ion-label>Saber más</ion-label>
            </ion-button>
            </AnimatedComponent>
          </div>
        </div>

        <div class="guardamuebles-slider">
          <swiper class="swiper-imagenes" :modules="modules" :slidesPerView="1" :autoplay="true" :loop="true" :pagination="true">
            <swiper-slide>
              <div class="swiper-imagen">
                <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles1_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-imagen">
                <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles2_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-imagen">
                <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles3_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-imagen">
                <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles4_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
              </div>
            </swiper-slide>
            <swiper-slide>
              <div class="swiper-imagen">
                <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles5_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
              </div>
            </swiper-slide>
          </swiper>
        </div>
      </section>

      
    <div class="container">
      <section id="pasos" class="pt-xxl mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12">
              <div class="title">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h2>El proceso de mudanza simplificado</h2>
                </AnimatedComponent>
              </div>
            </ion-col>
          </ion-row>
          <ion-row>
            <ion-col size="12" size-lg="4">
              <div class="pasos-container">
                <span class="number">01</span>
                <h3>Pide tu Presupuesto para la Mudanza</h3>
                <p class="mt-xxs mb-xxs">Pide tu presupuesto online sin compromiso.</p>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="pasos-container">
                <span class="number">02</span>
                <h3>Dejános ocuparnos de todo</h3>
                <p class="mt-xxs mb-xxs">Embalaje, Desmontaje y Montaje, Guradamuebles...</p>
              </div>
            </ion-col>
            <ion-col size="12" size-lg="4">
              <div class="pasos-container">
                <span class="number">03</span>
                <h3>Disfruta de tu nuevo hogar</h3>
                <p class="mt-xxs mb-xxs">Despreocúpate del proceso translado de tus enseres.</p>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="testimonials" class="pt-xxl mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="5">
              <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Ftestimonios_1024x1024.webp?alt=media" style="border-radius: 0 0 0 1rem;" alt="Mudanzas Chiclana Antonio Crespo" id="about-image"/>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="12" size-lg="6">
              <div class="testimonials-container mt-md">
                <AnimatedComponent animation-enter="animate__fadeInDown">
                <h2 class="text-light">Nuestros clientes nos avalan</h2>
                </AnimatedComponent>
                <img src="assets/img/iconos/quote.png" alt="Mudanzas Chiclana Antonio Crespo" class="quote-icon"/>

                <swiper class="swiper-testimonials" :modules="modules" :slidesPerView="1" :autoplay="true" :loop="true" :navigation="true">
                  <swiper-slide>
                    <div class="swiper-testimonial">
                      <p class="text-light">Buscando empresas de mudanzas me encontré con esta y me ha hecho mi mudanza, todo perfecto, buen trato con Antonio que me lo a arreglado todo, la llegada como estaba prevista y sin ningún problema. Empresa muy recomendable, muy buen trato.</p>
                      <p class="mt-xxs text-light"><b>Antonio Roberto</b></p>
                      <star-rating class="starts" :rating="5" read-only star-size="16" :show-rating="false"></star-rating>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="swiper-testimonial">
                      <p class="text-light">Excelente, presupuesto rápido y mudanza rápida. Útil con el desmantelamiento de algunos bienes; Todos los artículos del hogar fueron cargados y descargados rápidamente y en perfectas condiciones. Gracias</p>
                      <p class="mt-xxs text-light"><b>Helen Commandeur</b></p>
                      <star-rating class="starts" :rating="5" read-only star-size="16" :show-rating="false"></star-rating>
                    </div>
                  </swiper-slide>
                  <swiper-slide>
                    <div class="swiper-testimonial">
                      <p class="text-light">Muy contenta con el servicio recibido, formalidad, rapidez y mucho agrado mil gracias por todo.</p>
                      <p class="mt-xxs text-light"><b>Natalia Martin</b></p>
                      <star-rating class="starts" :rating="5" read-only star-size="16" :show-rating="false"></star-rating>
                    </div>
                  </swiper-slide>
                </swiper>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>

      <section id="contact" class="pt-xxl mb-xxl">
        <ion-grid>
          <ion-row>
            <ion-col size="12" size-lg="5">
              <div style="height: 100%;display: flex;align-items: end;">
                <div>
                  <AnimatedComponent animation-enter="animate__fadeInDown">
                  <h2>Tu Mudanza, en buenas manos</h2>
                  </AnimatedComponent>
                  <AnimatedComponent animation-enter="animate__fadeIn">
                  <ion-button class="mt-md mb-md" router-link="/presupuesto">
                    <ion-label>Pide tu presupuesto</ion-label>
                  </ion-button>
                  </AnimatedComponent>
                </div>
              </div>
            </ion-col>
            <ion-col size="1"></ion-col>
            <ion-col size="12" size-lg="6">
              <div id="camion1">
                <AnimatedComponent animation-enter="animate__slideInRight">
                  <img src="https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fcamion2_1024x1024.webp?alt=media" alt="Mudanzas Chiclana Antonio Crespo"/>
                </AnimatedComponent>
              </div>
            </ion-col>
          </ion-row>
        </ion-grid>
      </section>
      </div>

    <Footer></Footer>

    </ion-content>
  </ion-page>
</template>

<script>
import { IonContent, IonPage, IonButton, IonLabel, IonIcon, IonGrid, IonRow, IonCol, IonItem, IonList, IonCard, IonCardContent  } from '@ionic/vue';
import { arrowForwardSharp, arrowBackSharp, ellipseOutline, checkmarkSharp, callOutline, mailOutline } from 'ionicons/icons';

import Header from '@/components/Header.vue';
import { defineComponent } from 'vue';

import AnimatedComponent from '@/components/AnimatedComponent.vue';
import Footer from '@/components/Footer.vue';

import StarRating from 'vue-star-rating';

import { Pagination, Autoplay, Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/vue';

import 'swiper/css';
import "swiper/css/pagination";
import "swiper/css/navigation";
import '@ionic/vue/css/ionic-swiper.css';

import moment from 'moment';

export default defineComponent({
  name: 'HomePage',
  components: {
    IonPage,
    IonContent,
    Header,
    Footer,
    Swiper,
    SwiperSlide,
    AnimatedComponent,
    IonButton,
    IonLabel,
    IonGrid,
    IonRow,
    IonCol,
    IonItem,
    IonIcon,
    StarRating,
    IonList,
    IonCard, 
    IonCardContent
  },
  data() {
    return {
      slider: {},
      annos: moment().diff('1995-01-01', 'years'),
    }
  },
  methods: {

  },
  setup() {
    return { modules: [Pagination, Autoplay, Navigation], arrowForwardSharp, arrowBackSharp, ellipseOutline, checkmarkSharp, callOutline, mailOutline };
  }
});
</script>

<style scoped>

  .swiper-sliders{
    position: relative;
  }

  .swiper-sliders .swiper-slider .message{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    text-align: left;
    padding: 1rem;
    z-index: 2;
    text-align: center;
    width: 100%;
    max-width: 540px;
  }

  .swiper-sliders .swiper-slider{
      display: block;
      width: 100%;
      height: 100vh;
      position: relative;
      overflow: hidden;
      margin: 0;
      background: var(--ion-color-dark);
      background: linear-gradient(180deg, rgba(var(--ion-color-dark-rgb),0.8) 0%, rgba(var(--ion-color-dark-rgb),0.8) 100%);
  }

  .swiper-sliders .swiper-slider img{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: bottom center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      left: 0;
      right: 0;
      z-index: -1;
  }


  #contact-options{
    position: relative;
    top: 0;
    transform: translateY(-15%);
    z-index: 500;
  }

  #contact-options .presupuesto{
    --background: var(--ion-color-primary);
  }

  #contact-options .contact-option{
    margin: 1rem;
    border-radius: 0 0 0 1rem;
    box-shadow: -10px 5px 24px 0px rgb(86 85 85 / 12%);
  }

  #contact-options .contact-option h3 {
    color: var(--ion-color-dark);
  }

  #numbers .contador-box{
    margin: 1rem;
    padding: 1rem;
    border-radius: 0 0 0 1rem;
    border: 1px solid var(--ion-color-secondary);
  }

   #numbers .contador-box .image-box{
     margin: 2rem auto;
     width: 80px;
     height: 80px;
     display: block;
   }

   #camion1{
    height: 100%;
    display: flex;
    align-items: end;
   }

   #about .about-container{
     background: var(--ion-color-dark);
     border-radius: 0 0 0 1rem;
     padding: 2rem;
     box-shadow: -10px 5px 24px 0px rgb(86 85 85 / 12%);
   }

   #about .about-container .about-services .about-icon{
     padding: 1rem;
     border-radius: 0 0 0 1rem;
     background: var(--ion-color-light);
     width: 80px;
     height: 80px;
     display: block;
     margin-right: 2rem;
   }
    
    #about-image{
      width: 100%;
      height: 100%;
      height: 640px;
      
      object-fit: cover;
      object-position: center;
    }

   #services .service-container{
     border: 1px solid var(--ion-color-secondary);
     border-radius: 0 0 0 1rem;
     padding: 2rem;
     margin: 1rem;
   }

  #services .service-container .service-icon{
     margin-top: 2rem;
     width: 80px;
     height: 80px;
     display: block;
   }

   #services .service-button{
     display: table;
     margin: 0 auto;
     margin-top: 2rem;
   }

  #guardamuebles{
    background: linear-gradient(180deg, rgba(var(--ion-color-dark-rgb),0.8) 0%, rgba(var(--ion-color-dark-rgb),0.8) 100%), url('https://firebasestorage.googleapis.com/v0/b/mudanzascrespochiclana-com.appspot.com/o/blocks%2Fpages%2Fhome%2Fthumbnails%2Fguardamuebles-cover_2048x2048.webp?alt=media');
    background-position: center;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    overflow: visible;
    margin-bottom: 320px;
  }

  #guardamuebles .swiper-imagenes{
    position: absolute;
    transform: translateY(2rem);
    right: 0;
    width: 80%;
    max-width: 480px;
  }

  #guardamuebles .swiper-imagenes .swiper-imagen{
      display: block;
      width: 100%;
      height: auto;
      position: relative;
      overflow: hidden;
      padding: 100% 0 0 0; /* 34.37% = 100 / (w / h) = 100 / (640 / 220) */
      cursor: pointer;
      margin: 0;
      background: var(--ion-color-dark);
      border-radius: 0 0 0 1rem;
  }

  #guardamuebles .swiper-imagenes .swiper-imagen img{
      display: block;
      width: 100%;
      max-width: 100%;
      height: 100%;
      max-height: 100%;
      object-fit: cover;
      object-position: center;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      bottom: 0;
      left: 0;
      right: 0;
      transition: opacity 0.5s linear;
  }

  #pasos .pasos-container{
    border: 1px solid var(--ion-color-secondary);
    border-radius: 0 0 0 1rem;
    padding: 2rem;
    margin: 1rem;
  }

  #pasos .pasos-container .number{
    padding-bottom: 1rem;
    font-size: 3rem;
    color:  var(--ion-color-secondary);
  }

  #testimonials .testimonials-container{
    background: var(--ion-color-dark);
    border-radius: 0 0 0 1rem;
    padding: 2rem;
    box-shadow: -10px 5px 24px 0px rgb(86 85 85 / 12%);
  }

  #testimonials .testimonials-container .quote-icon{
    margin: 2rem;
    width: 80px;
    height: 80px;
    display: block;
  }

  #testimonials .swiper-testimonial{
    text-align: left;
    margin: 0 3rem;
  }

  .swiper-testimonial p{
    color: var(--ion-color-light);
  }

  .swiper-testimonial .starts{
    color: var(--ion-color-light);
    justify-content: start;
    margin-top: 0;
  }
  
 

  @media (max-width: 540px) {
  
  }

  @media (min-width: 540px) and (max-width: 720px) {
  
  }

  @media (min-width: 720px) and (max-width: 960px) {
   
  }

  @media (min-width: 960px) and (max-width: 1440px) {
    #contact-options{
      position: relative;
      top: 0;
      transform: translateY(-50%);
      z-index: 500;
    }

    #guardamuebles{
      margin-bottom: 240px;
    }

    #guardamuebles .swiper-imagenes{
      position: absolute;
      transform: translateY(-50%);
      right: 0;
      width: 80%;
      max-width: 480px;
    }
   
  }

  @media (min-width: 1440px) {
    #contact-options{
      position: relative;
      top: 0;
      transform: translateY(-50%);
      z-index: 500;
    }

    #guardamuebles{
      margin-bottom: 240px;
    }

    #guardamuebles .swiper-imagenes{
      position: absolute;
      transform: translateY(-50%);
      right: 0;
      width: 80%;
      max-width: 480px;
    }
  }

</style>