import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Menu = _resolveComponent("Menu")!
  const _component_ion_router_outlet = _resolveComponent("ion-router-outlet")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_fab = _resolveComponent("ion-fab")!
  const _component_ion_app = _resolveComponent("ion-app")!

  return (_openBlock(), _createBlock(_component_ion_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_Toast, {
        position: "top-right",
        group: "tr"
      }),
      _createVNode(_component_Menu),
      _createVNode(_component_ion_router_outlet, {
        id: "main",
        animation: _ctx.navAnimation
      }, null, 8, ["animation"]),
      _createVNode(_component_ion_fab, {
        vertical: "bottom",
        horizontal: "end",
        slot: "fixed"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_fab_button, {
            class: "whatsapp",
            href: "https://api.whatsapp.com/send?phone=34639977027&text=Hola,%20quer%C3%ADa%20mas%20informaci%C3%B3n%20sobre...",
            target: "_blank"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                icon: _ctx.logoWhatsapp,
                color: "light"
              }, null, 8, ["icon"])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}