
import { IonApp, IonRouterOutlet, createAnimation, IonIcon, IonFab, IonFabButton } from '@ionic/vue';
import { logoWhatsapp } from 'ionicons/icons';
import { defineComponent } from 'vue';

import Toast from 'primevue/toast';
import Menu from '@/components/Menu.vue';


export default defineComponent({
  name: 'App',
  components: {
    IonApp,
    IonRouterOutlet,
    Toast,
    Menu,
    IonIcon,
    IonFab,
    IonFabButton,
  },
  methods: {
    navAnimation(ElementRef, opts){
      const enterAnimation = createAnimation()
        .addElement(opts.enteringEl)
        .fromTo('opacity', '0', '1')
        .delay(250)
        .duration(125);

      const exitAnimation = createAnimation()
        .addElement(opts.leavingEl)
        .fromTo('opacity', '1', '0')
        .duration(125);

      const rootAnimation = createAnimation();

      rootAnimation.addAnimation([exitAnimation,enterAnimation]);

      return rootAnimation;
    },
  },
  setup() {
    return { logoWhatsapp };
  }
});
