<template>
  <div ref="target">
    <transition :name="animationType" :enter-active-class="'animate__animated ' + animationIn" @before-enter="afterEnter" :leave-active-class="'animate__animated ' + animationOut">
      <div v-appear="animate" class="animated-component">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script lang="js">
import { onMounted, ref } from 'vue';
export default {
  name: 'AnimatedComponent',
  props: {
    animationType: {
      type: String,
      required: false,
      default: 'fade',
    },
    animationEnter: String,
    animationLeave: String
  },
  data() {
    return  { 
             animationIn: this.animationEnter,
             animationOut: this.animationLeave 
            }
  },
  setup() {
    const target = ref();
    const animate = ref(false);
    const observer = new IntersectionObserver(
      ([entry]) => {
        animate.value = entry.isIntersecting;
      },
      {
        threshold: 0
      }
    );
    onMounted(() => {
      observer.observe(target.value);
    });
    return {
      animate,
      target
    };
  },
  methods:{
      afterEnter(el) {
          this.animationIn = undefined;
          this.animationOut = undefined;
      }
  },
};
</script>

<style scoped>
.animated-component.fade-enter-from,
.animated-component.zoom-enter-from {
  transition: none;
}
/* Fade animation
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1000ms ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.zoom-enter-active,
.zoom-leave-active {
  transition: transform 1000ms ease;
}
.zoom-enter-from,
.zoom-leave-to {
  transform: scale(0.9);
}*/
</style>