
import { IonHeader, IonLabel, IonToolbar, IonButton, IonMenuButton } from '@ionic/vue';
import { call, location, logoFacebook, logoInstagram } from 'ionicons/icons';

import { defineComponent,  } from 'vue';
import MegaMenu from 'primevue/megamenu';

export default defineComponent({
  name: 'HeaderComponent',
  props: ['activePage'],
  components: {
    IonHeader,
    IonToolbar,
    IonMenuButton,
    IonButton,
    IonLabel,
    MegaMenu
  },
  data() {
    return {
      items: [
                {
                    label: 'Inicio',
                    to: '/',
                    class: this.activePage == 'Inicio' ? 'active' : '',
                },
                {
                    label: 'Servicios',
                    to: '/servicios',
                    class: this.activePage == 'Servicios' ? 'active' : '',
                },
                {
                    label: 'Guardamuebles',
                    to: '/guardamuebles',
                    class: this.activePage == 'Guardamuebles' ? 'active' : '',
                },
                {
                    label: 'Sobre Nosotros',
                    to: '/sobre-nosotros',
                    class: this.activePage == 'Sobre' ? 'active' : '',
                },
                {
                    label: 'Contacto',
                    to: '/contacto',
                    class: this.activePage == 'Contacto' ? 'active' : '',
                },
                
            ]
    }
  },
  setup() {
    return { call, location, logoFacebook, logoInstagram};
  }
});
