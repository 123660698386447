
import {  IonFooter, IonIcon, IonLabel, IonItem, IonList, IonGrid, IonRow, IonCol, IonButton, IonButtons } from '@ionic/vue';
import { call, location, mail, logoFacebook, logoInstagram, arrowForwardSharp } from 'ionicons/icons';

import CookiesDialog from '@/components/CookiesDialog.vue';

import { defineComponent,  } from 'vue';

export default defineComponent({
  name: 'FooterComponent',
  components: {
    IonFooter,
    IonIcon,
    IonLabel,
    IonItem,
    IonList,
    IonGrid,
    IonRow,
    IonCol,
    IonButton,
    IonButtons,
    CookiesDialog
  },
  data() {
    return {
      anno: new Date().getFullYear()
    }
  },
  setup() {
    return { call, location, mail, logoFacebook, logoInstagram, arrowForwardSharp };
  }
});
